<template>
    <Panel>
        <div class="row">
            <div class="col-12 p-0">
                <div class="p-formgrid">                                
                    <div class="p-card-field p-grid">
                        <div class="col-12 d-flex pb-3 align-items-center">
                            <h5 class="pr-3 m-0">THÔNG TIN LIÊN HỆ</h5>
                            <Button class="p-button-success" style="width: 24px !important; height: 24px !important" icon="fa fa-plus" @click="addContact"/>
                        </div>
                        <div class="col-lg-6 col-12 py-3" v-for="(contact, index) in contacts" :key="index">
                            <Card>
                                <template #header>
                                    <div class="d-flex p-3 justify-content-between">
                                        <h5>NGƯỜI LIÊN HỆ {{ index + 1 }}</h5>
                                        <Button class="p-button-danger" icon="fa fa-trash" @click="delContact(index)"/>
                                    </div>
                                </template>
                                <!-- <template #title>
                                    Advanced Card
                                </template> -->
                                <template #content>
                                    <div class="row pt-0 px-2" style=" margin-top: -2rem;">
                                        <div class="col-12 pl-2">
                                            <label class="p-col-fixed txt-left">Họ và tên</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputText v-model="contact.name" placeholder="Nhập" :id='"name_contact_" + index' maxlength="255"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-12 pl-2">
                                            <label class="p-col-fixed txt-left">Số điện thoại</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputText v-model="contact.phone" placeholder="Nhập" :id='"phone_contact_"+index' maxlength="20"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-12 pl-2">
                                            <label class="p-col-fixed txt-left">Email</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputText v-model="contact.email" :id='"email_contact_"+index' placeholder="Email@example.com"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Card>
                        </div>
                        <div class="col-12 text-center" v-if="contacts.length === 0">
                            Chưa có liên hệ
                        </div>      
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>
<script>
export default {
  props: {
    contacts: Array,
    ContactDelete: Array
  },
  data() {
    return {
        tmp_contacts: [],
    }
  },
  async created() {
},
mounted(){
    this.addContact();
},
  methods: {

    addContact() {
      this.contacts.push({
        id: 'tmp_id-'+this.$commonFuction.generateUUID(),
        name: '',
        phone: '',
        email: '',
      })
    },
    delContact(index) {
        var check = this.contacts[index]?.id?.split("tmp_id") || [];
        if (check.length === 1) {
            this.ContactDelete.push(this.contacts[index].id);
        }
        this.contacts.splice(index, 1);
    },
    // validatePhone() {
    //     if (this.$commonFuction.isEmptyObject(this.selDocument) || (this.$commonFuction.isEmpty(this.qty_delivery) && this.$commonFuction.isEmpty(this.return_quantity))) {
    //         this.$toast.add({
    //             severity: 'error',
    //             summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //             detail: 'Vui lòng nhập đủ thông tin!',
    //             life: 3000
    //         });
    //         return;
    //     }
    // }
  }
}
</script>

<style lang="scss">
.freight-table input[disabled=disabled], .freight-table .vs--disabled .vs__dropdown-toggle {
    background: transparent;
    border: unset;
    opacity: 1;
}
.p-col-fixed.txt-left{
    margin-bottom: 0px !important;
}
button{
    .fa.fa-plus{
        width: 14px !important;
        height: 14px !important;
        font-size: 14px !important;
    }
}
.p-inputgroup-addon{
  height: 45px;
}
</style>

