<template>
    <Panel>
        <div class="row">
            <div class="col-12 pl-3 pr-3 pt-0">
                <div class="p-formgrid">                                
                    <div class="p-card-field p-grid">
                        <div class="col-12 p-0">
                            <h5>CHỨNG TỪ CẦN CÓ</h5>
                        </div>
                
                        <div class="p-lg-5 p-col-12 p-field no-padding">
                            <label class="p-col-fixed txt-left">Chọn chứng từ</label>
                            <div class="p-col pl-0">
                              <!-- v-if="!editMode" -->
                            <v-select  :clearable="false" title="Chọn chứng từ" id="documents" :filterable="false" placeholder="Chọn" v-model="selDocument" :options="listDocument" label="name" @search="fetchOptionsDocument" >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Không có dữ liệu
                                </span>
                                <template #selected-option="data">
                                  <span>{{ data?.type ? (data.type.replace("DELIVERY", 'Giao hàng').replace("RETURN", 'Trả hàng') + ' -') : '' }} {{ data.code }} - {{ data.name }}</span>
                                </template>
                                <template #option="data">
                                  <span>{{ data?.type ? (data.type.replace("DELIVERY", 'Giao hàng').replace("RETURN", 'Trả hàng') + ' -') : '' }} {{ data.code }} - {{ data.name }}</span>
                                </template>
                            </v-select>
                            <!-- <v-select v-if="editMode" disabled :clearable="false" title="Chọn chứng từ" id="documents" :filterable="false" placeholder="Chọn" v-model="selDocument" :options="listDocument" label="name" @search="fetchOptionsDocument" >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Không có dữ liệu
                                </span>
                            </v-select> -->
                            <input class="input_tmp_validator" id="document_id">
                            </div>
                        </div>

                        <div class="p-lg-3 p-col-6 p-field no-padding padding-left responsive-padding-right" >
                            <label class="p-col-fixed txt-left">SL khi giao hàng</label>
                            <div class="p-col">
                            <div class="p-inputgroup">
                                <InputNumber mode="decimal" :min="1" :minFractionDigits="0" v-model="qty_delivery" placeholder="Nhập" id="qty_delivery"/>
                            </div>
                            </div>
                        </div>
                        
                        <div class="p-lg-4 p-col-6 p-field no-padding responsive-padding-left padding-left">
                            <label class="p-col-fixed txt-left">SL khi trả hàng</label>
                            <div class="p-col">
                              <div class="p-inputgroup">
                                <InputNumber mode="decimal" :min="1" :minFractionDigits="0" v-model="return_quantity" placeholder="Nhập" id="return_quantity"/>
                                <Button
                                  v-if="!editMode"
                                  type="button"
                                  icon="pi pi-plus"
                                  class="d-lg-flex d-none p-button-success ml-2 px-4 rounded"
                                  @click="addDocument"
                                  severity="success"
                                />
                                <Button
                                  v-if="editMode"
                                  type="button"
                                  icon="pi pi-plus"
                                  class="d-lg-flex d-none p-button-success ml-2 px-4 rounded"
                                  @click="saveDocument"
                                  severity="success"
                                />
                              </div>
                            </div>
                        </div>

                        <div class="p-col-12 flex justify-content-end d-lg-none" style="padding-right: 0px !important">
                            <div class="p-col d-flex justify-content-end">
                            <Button v-if="!editMode" type="button" icon="pi pi-plus" class="p-button-success" @click="addDocument"/>
                            <Button v-if="editMode" type="button" icon="pi pi-check" class="p-button-success" @click="saveDocument"/>
                          </div>
                        </div>
                        
                        <div class="col-12 mt-lg-3 mb-lg-3 no-padding">
                            <DataTable :value="documents" responsiveLayout="scroll" class="document-table p-datatable-responsive">
                                <Column field="selDocument" header="Tên chứng từ">
                                <template #body="slotProps">
                                    <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                                    <!-- <div class="text-right mt-2"> -->
                                    <!-- <v-select :clearable="false" id= "documents" :disabled="!slotProps.data.edit" :filterable="false" placeholder="Chọn" v-model="slotProps.data.selDocument" :options="listDocument" label="name" @search="fetchOptionsDocument" class="no-arrow">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            Không có dữ liệu
                                        </span>
                                    </v-select> -->
                                    <!-- <InputText class="p-0 text-right" :disabled="!slotProps.data.edit" v-model="slotProps.data.selDocument.name" placeholder="Nhập" id="documents" style="overflow: auto !important;"/> -->
                                    <!-- </div> -->
                                    <span class="p-0 text-right" id="documents"> 
                                      {{ slotProps.data.selDocument?.type ? (slotProps.data.selDocument.type.replace("DELIVERY", 'Giao hàng').replace("RETURN", 'Trả hàng') + ' -') : '' }} {{ slotProps.data.selDocument.code }} - {{ slotProps.data.selDocument.name }}
                                    </span>
                                </template>
                                </Column>
                                <Column field="qty_delivery" header="SL khi giao hàng" headerStyle="width: 20%">
                                <template #body="slotProps">
                                    <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                                    <div class="text-right">
                                        <!-- {{ $commonFuction.numberFormat(data.qty_delivery, 0) }} -->
                                        <InputNumber class="p-0 text-right" mode="decimal" :disabled="!slotProps.data.edit" :minFractionDigits="0" v-model="slotProps.data.qty_delivery" placeholder="Nhập" id="qty_delivery"/>
                                    </div>
                                </template>
                                </Column>
                                <Column field="return_quantity" header="SL khi trả hàng" headerStyle="width: 20%">
                                <template #body="slotProps">
                                    <span class="responsive-label">{{ getLabel(slotProps) }}</span>
                                    <div class="text-right">
                                        <!-- {{ $commonFuction.numberFormat(data.return_quantity, 0) }} -->
                                        <InputNumber class="text-right" mode="decimal" :disabled="!slotProps.data.edit" :minFractionDigits="0" v-model="slotProps.data.return_quantity" placeholder="Nhập" id="return_quantity"/>
                                    </div>
                                </template>
                                </Column>
                                <Column header="" headerStyle="width: 10%">
                                <template #body="slotProps">
                                    <span class="responsive-label">Hành động</span>
                                    <div class="d-flex justify-content-around" v-if="!slotProps.data.edit">
                                        <Button type="button" icon="pi pi-pencil" class="p-button-edit" @click="editDocument(slotProps.index)"/>
                                        <Button type="button" icon="pi pi-trash" class="p-button-danger" @click="delDocument(slotProps.index)"/>
                                    </div>
                                </template>
                                </Column>

                                <template #empty>
                                <div class="text-center">{{ $t('datatable.empty') }}</div>
                                </template>
                            </DataTable>
                        </div>
                        <div class="col-12 p-0">
                            <label class="p-col-fixed txt-left mb-0">Lưu ý chứng từ</label>
                            <div class="p-col">
                            <Textarea class="form-control w-100" :autoResize="true" rows="3" type="text" v-model="NoteDoc" placeholder="Nhập" id="note_doc" maxlength="14000" @input="changeTextArea" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>
<script>
import DataServices from '@/core/DataServices'
export default {
  props: {
    documents: Array,
    DocumentDelete: Array,
    NoteDoc: String
  },
  data() {
    return {
        tmp_documents: [],
        selDocument: null,
        qty_delivery: null,
        return_quantity: null,
        listDocument: [],
        listDocumentAll: [],
        listAddedDocument: [],
        editMode: false,
        editIndex: null,
    }
  },
  async created() {
    await this.getListDocument();
  },
  methods: {
    getLabel(col) {
      // if (col.label) return col.label;
      // else return col.name;
      console.log(col.column.header);
      return col.column.header;
    },
    async getListDocument() {
        var where_key = {
            deleted : {_eq : false},
            active : {_eq : true}
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('document', {"fields" : `id code name type`}),
            variables: {
            where_key: where_key,
            orderBy:[{type : "asc"}, {name: "asc" }]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name :e.name, code :e.code, type :e.type});
        }
        this.listDocument = list_origin;
        this.listDocumentAll = list_origin;
    },
    
    async fetchOptionsDocument(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('document',{"fields" : `id code name type`}),
          variables: {
            where_key: where_key,
            orderBy:[{type : "asc"}, {name: "asc" }]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name :e.name, code :e.code, type :e.type});
        }
        this.listDocument = list_origin;
      }else{
        // cosnole.log("dasdasd")
        this.listDocument = this.listDocumentAll
      }
    },
    generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    toggleEditMode(index){
      this.editIndex = index;
      this.editMode = !this.editMode;
    },
    removeAvailableDocument(selDocument){
      var removeIndex = this.listDocument.findIndex(x => x.id === selDocument.id);
      this.listDocument.splice(removeIndex, 1);
    },
    addAvailableDocument(selDocument){
      this.listDocument.push(selDocument);
    },
    addDocument() {
        if (this.$commonFuction.isEmptyObject(this.selDocument) || (this.$commonFuction.isEmpty(this.qty_delivery) && this.$commonFuction.isEmpty(this.return_quantity))) {
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: 'Vui lòng nhập đủ thông tin!',
                life: 3000
            });
            return;
        }
        
        if (this.$commonFuction.isEmpty(this.qty_delivery)) this.qty_delivery = 0;
        if (this.$commonFuction.isEmpty(this.return_quantity)) this.return_quantity = 0;

        this.documents.push({
            id: 'tmp_id-'+this.$commonFuction.generateUUID(),
            selDocument: this.selDocument,
            qty_delivery: this.qty_delivery,
            return_quantity: this.return_quantity
        });
        this.removeAvailableDocument(this.selDocument);
        this.selDocument = null;
        this.qty_delivery = null;
        this.return_quantity = null;
    },
    delDocument(index) {
        console.log(this.documents[index]);
        var check = this.documents[index]?.id ? this.documents[index].id.split("tmp_id") : [];
        console.log("THis is Vbee", this.documents[index]);
        this.addAvailableDocument(this.documents[index].selDocument);
        if (check.length === 1) {
            this.DocumentDelete.push(this.documents[index].id);
        }
        this.documents.splice(index, 1);
    },
    editDocument(index) {
      // this.tmp_documents[0] = ({[this.documents[index].id]: this.documents[index]});
      this.selDocument = this.documents[index].selDocument;
      this.qty_delivery = this.documents[index].qty_delivery;
      this.return_quantity = this.documents[index].return_quantity;
      this.toggleEditMode(index);
      // this.delDocument(index);     . 
    },
    saveDocument() {
     if((this.$commonFuction.isEmpty(this.qty_delivery) && this.$commonFuction.isEmpty(this.return_quantity))) {
        this.$toast.add({
            severity: 'error',
            summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: 'Vui lòng nhập đủ thông tin!',
            life: 3000
        });
        return;
      }
      if (this.$commonFuction.isEmpty(this.qty_delivery)) this.qty_delivery = 0;
      if (this.$commonFuction.isEmpty(this.return_quantity)) this.return_quantity = 0;
      this.documents[this.editIndex].qty_delivery = this.qty_delivery;
      this.documents[this.editIndex].return_quantity = this.return_quantity;
      this.toggleEditMode(null);
      this.selDocument = null;
      this.qty_delivery = null;
      this.return_quantity = null;
    },
    async cancelDocument(index) {
        this.$delete(this.documents[index], 'edit');
        var check = this.tmp_documents.filter(m => {  return m[this.documents[index].id] })
        for await (const [key, value] of Object.entries(check[0][this.documents[index].id])) {
            this.$set(this.documents[index], key, value);
        }
        this.$delete(this.documents[index], 'edit');
        this.tmp_documents.map(m => {  delete m[this.documents[index].id] })
    },
    changeTextArea(value) {
      console.log("LOLOLOL", value);
         this.$emit("update-note-doc", value)
    }
  }
}
</script>

<style lang="scss">
.document-table input[disabled=disabled], .document-table .vs--disabled .vs__dropdown-toggle {
    background: transparent;
    border: unset;
    opacity: 1;
}
.customer-form .p-panel-header{
  background-color: transparent !important;
}
@media screen and (max-width: 900px) {
  .p-datatable.p-datatable-responsive {
      .p-datatable-tbody > tr > td {
        align-items: center;
        .responsive-label{
          margin-bottom: 0px !important;
        }
      }
      .p-datatable-tbody > tr > td {
        div {
          button:first-child{
            margin-right: 16px !important; 
          }
          button:last-child{
            margin-right: 0 !important;
          }
        }
      }
      .p-datatable-tbody > tr > td:last-child {
        margin-top: 8px;
        .responsive-label{
          margin-bottom: 0px !important;
        }
      }
      .p-datatable-tbody > tr > td:last-child {
        margin-top: 8px;
        .responsive-label{
          padding-top: 0px !important;
          margin-bottom: 0px !important;
        }
      }
      .p-datatable-tbody > tr > td:first-child {
        border: unset !important;
      }
  }
}

.text-right{
  span{
    input{
      min-height: unset;
      height: unset;
    }
  }
}

.no-arrow{
  .vs__actions{
    display: none !important;
  }
}
.vs--single .vs__selected {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 85%;
    padding: 0;
    display: block;
    align-self: center;
}
</style>
