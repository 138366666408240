<template>
    <form id="frm_action">
        <div class="p-formgrid customer-form">
            <div class="p-col-12 p-lg-12 view-action">
                <div class="card-w-title">
                    <Panel>
                        <div class="p-formgrid">
                            <div class="p-card-field p-grid">
                              <div class="col-12 p-0">
                                <h5>THÔNG TIN KHÁCH HÀNG</h5>
                              </div>
                                <div class="p-field p-lg-6 p-col-12 no-padding p-0">
                                    <label class="p-col-fixed txt-left">Tên khách hàng <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <InputText v-model="name" type="text" id="name" placeholder="Nhập" maxlength="255"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-lg-6 no-padding d-lg-block d-none">
                                    <label class="p-col-fixed txt-left">Nhóm kênh khách hàng</label>
                                    <div class="p-col">
                                        <InputText v-model="group_channel_code" type="text" id="group_channel_code" disabled="disabled"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-lg-6 p-col-12 no-padding p-0">
                                    <label class="p-col-fixed txt-left">Mã công ty <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                      <v-select :clearable="false" title="Mã công ty" id="a2" :filterable="false" placeholder="Chọn" v-model="selCompany" :options="listCompany" label="code" @search="fetchOptionsCompany" >
                                          <span slot="no-options" @click="$refs.select.open = false">
                                              Không có dữ liệu
                                          </span>
                                      </v-select>
                                      <input class="input_tmp_validator" id="company_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-lg-6 no-padding d-lg-block d-none">
                                    <label class="p-col-fixed txt-left">Kênh phân phối</label>
                                    <div class="p-col">
                                        <InputText v-model="channel_code" type="text" id="channel_code" disabled="disabled"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-lg-6 p-col-12 no-padding p-0">
                                  <label class="p-col-fixed txt-left">Miền</label>
                                  <div class="p-col">
                                    <v-select :clearable="false" title="Miền" id= "a1" :filterable="false" placeholder="Chọn" v-model="selRegion" :options="listRegion" label="name" @search="fetchOptionsRegion" >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            Không có dữ liệu
                                        </span>
                                    </v-select>
                                    <input class="input_tmp_validator" id="region_id">
                                  </div>
                              </div>
                              <!-- .p-field.no-padding.p-lg-6.d-lg-block.d-none,
    .p-field.col-lg-6.col-12.no-padding.d-lg-block.d-none {
      padding-left: 16px !important;
    } -->
                              <div class="p-field p-lg-6 no-padding d-lg-block d-none" >
                                <label class="p-col-fixed txt-left">Mã khách hàng</label>
                                <div class="p-col">
                                  <div class="p-inputgroup">
                                    <InputText v-model="code" placeholder="Nhập" id="code" :disabled="(mode === 'edit')"/>
                                  </div>
                                </div>
                              </div>

                                
                              <div class="p-field p-lg-6 p-col-12 no-padding p-0">
                                  <label class="p-col-fixed txt-left">Mã nhóm kênh <span class="span-cus">*</span></label>
                                  <div class="p-col">
                                    <v-select :clearable="false" title="Mã nhóm kênh" id= "a3" :filterable="false" placeholder="Chọn" v-model="selGroupChannel" :options="listGroupChannel" label="code" @search="fetchOptionsGroupChannel" >
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            Không có dữ liệu
                                        </span>
                                    </v-select>
                                    <input class="input_tmp_validator" id="group_channel_id">
                                  </div>
                              </div>

<!--                              <div class="p-field p-lg-6 p-col-12 no-padding d-lg-block d-none" v-if="mode === 'add'">
                                <label class="p-col-fixed txt-left">Thời gian giao hàng cam kết2</label>
                                <div class="p-col">
                                  <div class="p-inputgroup">
                                    <InputNumber mode="decimal" :minFractionDigits="0" v-model="commitment_time" placeholder="Nhập" id="commitment_time"/>
                                    <span class="p-inputgroup-addon">
                                        ngày
                                    </span>
                                  </div>
                                </div>
                              </div>-->
                              <div class="p-field p-lg-6 p-col-12 no-padding p-0">
                                <div class="p-col-12 p-0 d-lg-block d-none" >
                                  <label class="p-col-fixed txt-left ml-lg-3">Thời gian giao hàng cam kết</label>
                                  <div class="p-col ml-lg-3">
                                    <div class="p-inputgroup">
                                      <InputNumber mode="decimal" :minFractionDigits="0" v-model="commitment_time" placeholder="Nhập" id="commitment_time"/>
                                      <span class="p-inputgroup-addon">
                                          ngày
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="p-field p-col-12 no-padding d-lg-none d-block">
                                    <label class="p-col-fixed txt-left">Nhóm kênh khách hàng</label>
                                    <div class="p-col">
                                        <InputText v-model="group_channel_code" type="text" id="group_channel_code" disabled="disabled"/>
                                    </div>
                                </div>

                                <div class="p-field p-col-12 no-padding d-lg-none d-block">
                                    <label class="p-col-fixed txt-left">Kênh phân phối</label>
                                    <div class="p-col">
                                        <InputText v-model="channel_code" type="text" id="channel_code" disabled="disabled"/>
                                    </div>
                                </div>
                                <div class="p-field p-col-12 no-padding d-lg-none d-block" >
                                  <label class="p-col-fixed txt-left">Mã khách hàng</label>
                                  <div class="p-col">
                                    <div class="p-inputgroup">
                                      <InputText v-model="code" placeholder="Nhập" id="code"  :disabled="(mode === 'edit')"/>
                                    </div>
                                  </div>
                                </div>
                                <div class="p-field p-col-12 no-padding d-lg-none d-block">
                                <label class="p-col-fixed txt-left">Thời gian giao hàng cam kết</label>
                                <div class="p-col">
                                  <div class="p-inputgroup">
                                    <InputNumber mode="decimal" :minFractionDigits="0" v-model="commitment_time" placeholder="Nhập" id="commitment_time"/>
                                    <span class="p-inputgroup-addon">
                                        ngày
                                    </span>
                                  </div>
                                </div>
                              </div>

                                <div class="p-col-12 p-0 pl-lg-3">
                                  <div class="d-flex justify-content-start align-items-center">
                                    <Checkbox :binary="true" id="request_pay" v-model="request_pay" />
                                    <label class="pl-3" for="request_pay">Yêu cầu thanh toán (đơn hàng bắt buộc phải xác nhận thanh toán mới có thể điều phối)</label>
                                  </div>
                                </div>
                                <div class="p-col-12 p-0 pl-lg-3">
                                  <div class="d-flex justify-content-start align-items-center">
                                    <Checkbox :binary="true" id="is_pickup" v-model="is_pickup" />
                                    <label class="pl-3" for="is_pickup">Khách hàng tự đến lấy hàng </label>
                                  </div>
                                </div>
                              </div>
                                
                            </div>
                        </div>
                    </Panel>
                    <CustomerDocument :documents="documents" :DocumentDelete="DocumentDelete" :NoteDoc="note_doc" @update-note-doc="(value) => { this.note_doc = value }"/>
                    <CustomerContact :contacts="contacts" :ContactDelete="ContactDelete" />
                    <CustomerDelivery :deliveries.sync="deliveries" :DeliveryDelete="DeliveryDelete" :mode="mode" :CustomerCode="code" :countDelivery="count_delivery"/>
                    <CustomerFrieght :mode="mode" :freights="freights" :FreightDelete="FreightDelete"/>
                    <Panel>
                    <div class="row">
                      <div class="col-12 pl-3 pr-3 pt-0">
                        <div class="p-formgrid">
                          <div class="p-card-field p-grid">
                            <div class="col-12 p-0">
                              <h5>GHI CHÚ CHUNG</h5>
                            </div>
                            <div class="col-12 pl-0 pr-0 pb-0 pt-2">
                              <div class="p-col p-0">
                                <Textarea class="form-control w-100" :autoResize="true" rows="3" v-model="note" placeholder="Nhập" id="note" @input="changeTextArea"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Panel>
                    <div class="p-col-12 p-0 pt-3">
                      <div class="d-flex justify-content-start align-items-center">
                        <Checkbox :binary="true" id="active" v-model="active" />
                        <label class="pl-3 mb-1" for="active">Hoạt động</label>
                      </div>
                    </div>
                </div>
            </div>
          <Toolbar class="fixed-bottom-toolbar">
            <template slot="right">
              <Button
                :label="$constants.TEXT_BUTTON.BACK"
                @click="backToList()"
                class="p-button-warning callback-btn"
              />
              <Button
                v-if="mode == 'edit'"
                :label="$constants.TEXT_BUTTON.EDIT"
                @click="saveData()"
                class="main-style-button"
              />
              <Button
                v-else
                :label="$constants.TEXT_BUTTON.ADD"
                @click="saveData()"
                class="main-style-button"
              />
            </template>
          </Toolbar>
        </div>
    </form>
</template>

<script>
// import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
// import ApiRepository from "@/core/ApiRepository";
// import BaseDatatable from './list'
import CustomerDocument from './CustomerDocument';
import CustomerFrieght from './CustomerFrieght';
import CustomerDelivery from './CustomerDelivery';
import CustomerContact from './CustomerContact';
export default {
  components: {
    // BaseDatatable
    CustomerDocument,
    CustomerFrieght,
    CustomerDelivery,
    CustomerContact
  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean
  },
  data() {
    return {
      code: '',
      name: '',
      contacts: [],
      ContactDelete: [],
      deliveries: [],
      DeliveryDelete: [],
      freights: [],
      FreightDelete: [],
      documents: [],
      DocumentDelete: [],
      active: true,
      request_pay: false,
      is_pickup: false,
      selCompany: null,
      listCompany: [],
      listCompanyAll: [],
      channel_code: '',
      group_channel_code: '',
      commitment_time: null,
      note: '',
      note_doc: '',
      selGroupChannel: null,
      listGroupChannel: [],
      listGroupChannelAll: [],
      channel_id: null,
      prefix_code_cus: null,
      
      selRegion: null,
      listRegion: [],
      listRegionAll: [],
      count_delivery: 0,
      loadEdit: false,
    }
  },
  watch: {
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    },
    'selMaterial'() {
        if (this.selMaterial) {
          this.material_code = this.selMaterial?.code;
      }
    },
    'infinite_date'() {
        if (this.infinite_date) {
          this.date_profile = null;
      }
    },
    'selGroupChannel'() {
      this.group_channel_code = this.selGroupChannel?.name || null;
      this.channel_code = this.selGroupChannel?.channel?.code || '';
      this.channel_id = this.selGroupChannel?.channel?.id || null;
      this.prefix_code_cus = this.selGroupChannel?.channel?.prefix_code_cus || null;
    },
    async 'selCompany'() {
      await this.getListGroupChannel();
      if (!this.loadEdit) {
        if (this.selGroupChannel?.id) {
          var check = this.listGroupChannel.filter((e) => e.id == this.selGroupChannel?.id);
          if (this.$commonFuction.isEmptyObject(check) || check.length === 0) {
            this.$set(this, 'selGroupChannel', null);
          }
        }
      } else {
        this.loadEdit = false;
      }
    }
  },
  async mounted () {
    console.log("sadasdasdas:",this.mode)
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }

  },
  async created() {
    await this.getListCompany();
    await this.getListGroupChannel();
    await this.getListRegion();
  },
  methods: {
    changeTextArea(event) {
        this.note = event.target.value;
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-customer/edit/' + this.models.id, params: {id: this.models.id}});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-customer' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    
    async getListRegion() {
        var where_key = {
            deleted : {_eq : false}
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('region', {"fields" : `id name`}),
            variables: {
            where_key: where_key,
            orderBy:[{ordinal : "asc" }]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name: e.name});
        }
        this.listRegion = list_Region;
        this.listRegionAll = list_Region;
    },
    
    async fetchOptionsRegion(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('region',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Region = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Region.push({id : e.id, name :e.name});
        }
        this.listRegion = list_Region;
      }else{
        // cosnole.log("dasdasd")
        this.listRegion = this.listRegionAll
      }
    },
    
    async getListCompany(){

      var where_key = {
        deleted : {_eq : false},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('company',{"fields" : `id code name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_company = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_company.push({id : e.id, name :e.name, code: e.code});
      }
      this.listCompany = list_company;
      this.listCompanyAll = list_company;
    },
    
    async fetchOptionsCompany(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
          _or: {
            code:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"}
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company',{"fields" : `id code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_company = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_company.push({id : e.id, name :e.name, code: e.code});
        }
        this.listCompany = list_company;
      }else{
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll
      }
    },
    async getListGroupChannel(){

      var where_key = {
        active : {_eq : true},
        deleted : {_eq : false},
        channel: {deleted: {_eq: false}},
        group_channel_companies: {company_id: {_eq: this.selCompany?.id}}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('group_channel',{"fields" : `id name code channel{id code prefix_code_cus}`}),
        variables: {
          where_key: where_key,
          orderBy:[{ordinal : 'asc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_GroupChannel = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_GroupChannel.push({id : e.id, name :e.name, code: e.code, channel: e.channel});
      }
      this.listGroupChannel = list_GroupChannel;
      this.listGroupChannelAll = list_GroupChannel;
    },
    async fetchOptionsGroupChannel(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          channel: {deleted: {_eq: false}},
          group_channel_companies: {company_id: {_eq: this.selCompany?.id}},
          code:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"}
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('group_channel',{"fields" : `id name code channel{id code prefix_code_cus}`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_GroupChannel = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_GroupChannel.push({id : e.id, name :e.name, code: e.code, channel: e.channel});
        }
        this.listGroupChannel = list_GroupChannel;
      }else{
        // cosnole.log("dasdasd")
        this.listGroupChannel = this.listGroupChannelAll
      }
    },
    // async IsEmail(email){
    //   const emailRegexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    //   return emailRegexp.test(email)
    // },
    validatePhone(phone){
      for (let i = 0; i < phone.length; i++){
        if (!((phone[i] >= '0' &&  phone[i] <= '9') || (i == 0 && phone[i] == '+'))){
          return false;
        }
      }
      if (phone == "+") return false;
      return true;
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      console.log("SHIBABABABABA", re.test(email));
      return re.test(email);
    },
    checkDuplicatedAddress(){
      //create address for each delivery in this.deliveries by concatenating country, state, district, ward, street and check if there is any duplicated address
      var listAddress = [];
      console.log("ARRAY size", this.deliveries.length);
      console.log("ARRAY content", this.deliveries);
      let newArray = this.deliveries.forEach((e, index) => {
        console.log("An element in deliveries", e, index);
        if (this.$commonFuction.isEmpty(e.selCountry?.name) && this.$commonFuction.isEmpty(e.selState?.name) && this.$commonFuction.isEmpty(e.selDistrict?.name) && this.$commonFuction.isEmpty(e.selWard?.name) && this.$commonFuction.isEmpty(e.street)) {
          console.log("GONNA DELETE THIS", e, index);
          this.$delete(this.deliveries, index);
          return;
        }
        else if (this.$commonFuction.isEmpty(e.country_id) && this.$commonFuction.isEmpty(e.state_id) && this.$commonFuction.isEmpty(e.district_id) && this.$commonFuction.isEmpty(e.ward_id) && this.$commonFuction.isEmpty(e.street)) {
          console.log("GONNA DELETE THIS", e, index);
          this.$delete(this.deliveries, index);
          return;
        }
      })
      
      console.log("Eliminating result", newArray);
      this.deliveries.map((e) => {
        var address = "";
        if (e.selCountry?.name) address += e.selCountry?.name;
        if (e.selState?.name) address += e.selState?.name;
        if (e.selDistrict?.name) address += e.selDistrict?.name;
        if (e.selWard?.name) address += e.selWard?.name;
        if (e.street) address += e.street;
        address = address.toLowerCase();
        listAddress.push(address);
      })
      var listAddressUnique = [...new Set(listAddress)];
      if (listAddress.length != listAddressUnique.length) {
        return true;
      }
      return false;
      
    },
    async validateData() {
      this.validationErrors = {};

      let code_add = document.getElementById("code");
      if (this.$commonFuction.isEmpty(this.code)) {
        code_add.setCustomValidity("Mã khách hàng không được để trống!");
      } else {
        code_add.setCustomValidity("");
      }


      let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmpty(this.name)) {
        name_add.setCustomValidity("Tên khách hàng không được để trống!");
      } else {
        name_add.setCustomValidity("");
      }


      let company_add = document.getElementById("company_id");
      if (this.$commonFuction.isEmptyObject(this.selCompany)) {
        company_add.setCustomValidity("Mã công ty không được để trống!");
      } else {
        company_add.setCustomValidity("");
      }

      // let region_add = document.getElementById("region_id");
      // if (this.$commonFuction.isEmptyObject(this.selRegion)){
      //     region_add.setCustomValidity("Miền không được để trống!");
      // }else {
      //     region_add.setCustomValidity("");
      // }


      let channel_add = document.getElementById("group_channel_id");
      if (this.$commonFuction.isEmptyObject(this.selGroupChannel)) {
        channel_add.setCustomValidity("Mã nhóm kênh không được để trống!");
      } else {
        channel_add.setCustomValidity("");
      }

      let commitment_time_add = document.getElementById("commitment_time");
      if (!this.$commonFuction.isEmpty(this.commitment_time)) {
        if (this.commitment_time < 1) {
          commitment_time_add.setCustomValidity("Thời gian giao hàng cam kết không được nhỏ hơn 1!");
        } else commitment_time_add.setCustomValidity("");
      }

        this.contacts.map((e, index) => {
          if ( this.$commonFuction.isEmpty(e.name) && this.$commonFuction.isEmpty(e.phone) && this.$commonFuction.isEmpty(e.email)) {
            this.$delete(this.contacts, index);
            return;
          }
          let name_add = document.getElementById("name_contact_"+index);
          if (this.$commonFuction.isEmpty(e.name)){
              name_add.setCustomValidity("Tên liên hệ không được để trống!");
          } else if (e.name.length > 255) {
              name_add.setCustomValidity("Tên liên hệ không được vượt quá 255 ký tự!");
          } else {
              name_add.setCustomValidity("");
          }
          
          let phone_add = document.getElementById("phone_contact_"+index);
          if (this.$commonFuction.isEmpty(e.phone)){
              phone_add.setCustomValidity("Số điện thoại không được để trống!");
          } else if (!this.validatePhone(e.phone)) {
              phone_add.setCustomValidity("Số điện thoại không hợp lệ!");
          } else {
              phone_add.setCustomValidity("");
          }
          
          let email_add = document.getElementById("email_contact_"+index);
          if (this.$commonFuction.isEmpty(e.email)){
              email_add.setCustomValidity("Email không được để trống!");
          } else if (!this.validateEmail(e.email)) {
              email_add.setCustomValidity("Email không hợp lệ!");
          } else {
              email_add.setCustomValidity("");
          }
        })

        if (this.checkDuplicatedAddress()){
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: 'Địa chỉ giao hàng không được trùng nhau!',
              life: 3000
          });
          return true;
        }

        var inpObj = document.getElementById("frm_action");
        if (!inpObj.checkValidity()){
            console.log('test=====================');
            inpObj.reportValidity();
            return true;
        }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      try {
        this.$emit('update:triggerSave', false);
        console.log(" this.freights:", this.freights)
        if (await this.validateData()) {
          this.$commonFuction.is_loading(false);
          return
        }
        //check mã kh bị trùng
        if (this.mode=='add'){
          var where_cus = {
            code:{ _ilike: this.code },
            deleted: { _eq: false },
            company_id: { _eq: this.selCompany.id },
          };
          var order_by = { created_at: "desc" };
          var check_customer = await this.$CoreService.getListData(
              "customer",
              where_cus,
              0,
              1,
              order_by
          );
          if (check_customer.length > 0){
            alert("Mã khách hàng đã tồn tại!");
            this.$commonFuction.is_loading(false);
            return false;
          }
        }


        var list_tmp = []
        for (var j=0;j<this.freights.length;j++){
          var item_data = this.freights[j];
          var freight_id =  item_data.selFreight?.id || null
          var unit_price =  item_data?.unit_price ?? null
          console.log("item_data:",item_data)
          if (this.$commonFuction.isEmpty(freight_id) ){
            alert("Vui lòng chọn chành xe!");
            this.$commonFuction.is_loading(false);
            return false;
          }
          if (this.$commonFuction.isEmpty(unit_price) ){
            alert("Vui lòng nhập đơn giá chành!");
            this.$commonFuction.is_loading(false);
            return false;
          }else if (parseFloat(unit_price) <= 0 ) {
            alert("Vui lòng nhập đơn giá chành!");
            this.$commonFuction.is_loading(false);
            return false;
          }
          //var freight_id = item_data.selFreight.id
          if (list_tmp.indexOf(freight_id) !=-1){
            alert("Chành xe bị trùng lặp dữ liệu!");
            this.$commonFuction.is_loading(false);
            return false;
          }
          list_tmp.push(freight_id)
        }
        console.log("list_tmp:",list_tmp)
        this.submitData();
      } catch(e) {
        console.log(e);
        this.$commonFuction.is_loading(false);
        this.$toast.add({
            severity: 'error',
            summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: 'Có gì đó sai sai! Vui lòng thử lại',
            life: 3000
        });
      }
    },
    async submitData() {
        this.$commonFuction.is_loading(true);
        this.showWarningDialog = false;
        this.contacts.map((e, index) => {
          this.$delete(e, 'id');
          if ( this.$commonFuction.isEmpty(e.name) && this.$commonFuction.isEmpty(e.phone) && this.$commonFuction.isEmpty(e.email)) {
            this.$delete(this.contacts, index);
          }
        })
     /* console.log("this.deliveriesthis.deliveries:",this.deliveries)
      var testss=1;
      if (testss==1){
        return false;
      }*/
        this.deliveries.map((m, index) => {
          console.log("MeoMeo", index, m);
          this.$set(m, 'code', this.code + "_" + this.$commonFuction.padNum(index + 1, 3));
          this.$set(m, 'ward_id', (m.selWard?.id || null));
          this.$set(m, 'country_id',( m.selCountry?.id || null));
          this.$set(m, 'state_id', (m.selState?.id || null));
          this.$set(m, 'district_id', (m.selDistrict?.id || null));
          if (this.mode === 'edit') {
              this.$set(m, 'check_code', true);
          }

          var street_key =(m.street) ? m.street:''
          var country_name_key =m.selCountry?.name || null
          var state_name_key =m.selState?.name || null
          var district_name_key =m.selDistrict?.name || null
          var ward_name_key =m.selWard?.name || null
          var full_address = "";
          if (!this.$commonFuction.isEmpty(street_key)) {
            full_address +=street_key+", "
          }
          if (!this.$commonFuction.isEmpty(ward_name_key)) {
            full_address +=ward_name_key+", "
          }
          if (!this.$commonFuction.isEmpty(district_name_key)) {
            full_address +=district_name_key+", "
          }
          if (!this.$commonFuction.isEmpty(state_name_key)) {
            full_address +=state_name_key+", "
          }
          if (!this.$commonFuction.isEmpty(country_name_key)) {
            full_address +=country_name_key+""
          }
          this.$set(m, 'address', full_address);
          //var street = params.address+', '+ params.ward_name +', '+ params.district_name +', '+ params.state_name+', '+ params.country_name
          // this.$delete(m, 'id');
          this.$delete(m, 'selWard');
          this.$delete(m, 'selCountry');
          this.$delete(m, 'selState');
          this.$delete(m, 'selDistrict');
          
          this.$delete(m, 'listState');
          this.$delete(m, 'listStateAll');
          this.$delete(m, 'listDistrict');
          
          this.$delete(m, 'listDistrictAll');
          this.$delete(m, 'listWard');
          this.$delete(m, 'listWardAll');
        })
        
        this.documents.map(m => {
          this.$set(m, 'doc_id', m.selDocument?.id || null);
          this.$delete(m, 'id');
          this.$delete(m, 'selDocument');
        })
        
        this.freights.map(m => {
          this.$set(m, 'freight_id', m.selFreight?.id || null);
          // this.$delete(m, 'id');
          this.$delete(m, 'code');
          this.$delete(m, 'selFreight');
        })
        console.log("this.is_pickupthis.is_pickup:",this.is_pickup)
        console.log("this.is_pickupthis.Boolean(this.is_pickup):",Boolean(this.is_pickup))
        var params = {
          code: this.code,
          name: this.name,
          company_id: this.selCompany?.id,
          group_channel_id: this.selGroupChannel?.id,
          channel_id: this.channel_id,
          region_id: this.selRegion?.id,
          documents: this.documents,
          deliveries: this.deliveries,
          contacts: this.contacts,
          freights: this.freights,
          active: Boolean(this.active),
          note: this.note,
          note_doc: this.note_doc,
          request_pay: Boolean(this.request_pay),
          is_pickup: Boolean(this.is_pickup),
          DeliveryDelete: this.DeliveryDelete,
          ContactDelete: this.ContactDelete,
          FreightDelete: this.FreightDelete,
          DocumentDelete: this.DocumentDelete,
          commitment_time: this.commitment_time,
          prefix_code_cus: this.prefix_code_cus,
        }
        console.log("params", params);
        this.$emit('save', params);
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('customer', 
        {"fields" : "id name code active note_doc note request_pay is_pickup commitment_time group_channel{id name code channel{id code prefix_code_cus}} region{id name} company{id name code}  region{id name} company{id name code} customer_documents(where:{deleted: {_eq: false}}){document{id name code type} qty_delivery return_quantity} customer_deliveries(where: {deleted: {_eq: false } }) {id code ordinal country{id name} state{id name} district{id name} ward{id name} street} freight_customers(where: {deleted: {_eq: false } }) {id freight{id code name} unit_price} contacts {id name phone email}"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.code = this.models?.code;
        this.name = this.models?.name;
        this.loadEdit = true;
        this.selCompany = this.models?.company ? {id: this.models?.company?.id, name: this.models?.company?.name, code: this.models?.company?.code} : null;
        this.selRegion = this.models?.region ? {id: this.models?.region?.id, name: this.models?.region?.name} : null;
        this.selGroupChannel = this.models?.group_channel ? {id: this.models?.group_channel?.id, name: this.models?.group_channel?.name, code: this.models?.group_channel?.code, channel: this.models?.group_channel?.channel} : null;
        this.commitment_time = this.models.commitment_time;
        this.documents = this.models?.customer_documents.map(e => { return {id:e.id, selDocument: e?.document?.id ? {id: e.document?.id, name: e.document?.name, code: e.document?.code, type: e.document?.type} : null, qty_delivery: e.qty_delivery,  return_quantity: e.return_quantity}  }) || [];
        this.deliveries = this.models?.customer_deliveries.map(e => { return {id:e.id, code: e.code, ordinal: e.ordinal, selCountry: e?.country?.id ? {id: e.country?.id, name: e.country?.name} : null,  selState: e?.state?.id ? {id: e.state?.id, name: e.state?.name} : null, selDistrict: e?.district?.id ? {id: e.district?.id, name: e.district?.name} : null, selWard: e?.ward?.id ? {id: e.ward?.id, name: e.ward?.name} : null, street: e.street } }) || [];
        this.freights = this.models?.freight_customers.map(e => { return {id:e.id, selFreight: e?.freight?.id ? {id: e.freight?.id, name: e.freight?.name, code: e.freight?.code,} : null, unit_price: e.unit_price }  }) || [];
        this.contacts = this.models?.contacts.map(e => { return {id:e.id, name: e.name, phone: e.phone, email: e.email}  }) || [];
        this.active = this.models.active;
        this.note = this.models?.note;
        this.note_doc = this.models?.note_doc;
        this.request_pay = this.models.request_pay;
        this.is_pickup = this.models.is_pickup;
        this.$set(this, 'count_delivery', this.deliveries.length);
        this.componentKey++;
      }
    }
  },
  apollo: {
  },
  
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.p-panel {
  .p-panel-header{
    background: unset;
    border: none;
    border-bottom: 1px solid #c8c8c8;
  }
  .p-panel-content{
    background: #FAFAFA;
  }
}

input[disabled="disabled"] {
  background: #D0D4D9;
}
  
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #C40380 !important;
    color: #ffffff;
  }
  .span-cus {
    color : red;
  }
}
.customer-form .p-panel-header{
  background-color: transparent !important;
}

.p-field.no-padding {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  
  label.p-col-fixed.txt-left {
    padding-left: 0 !important;
  }
  
  .p-card-field.p-grid.pt-0 {
    padding-top: 0 !important;
  }
  .p-panel-content {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-bottom: 0px !important;
  }
  .p-col {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-right: 0 !important;
  }

  .text-right{
    span{
      input{
        text-align: right;
      }
    }
  }
  @media (max-width: 991px) {
    .p-card-field.p-grid {
      padding: 16px !important;
      .col-12.no-padding {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
      .col-12.no-padding.responsive-padding-left, .p-col-6.no-padding.responsive-padding-left {
        padding-left: 8px !important;
      }
      .col-12.no-padding.responsive-padding-right, .p-col-6.no-padding.responsive-padding-right {
        padding-right: 8px !important;
      }
    }
    .p-card-field.p-grid.responsive-no-pt {
      padding-top: 0 !important;
    }
    .p-card-field.p-grid.responsive-pb-2 {
      padding-bottom: 8px !important;
    }
  }
  @media (min-width: 992px) {
    .p-card-field.p-grid {
      padding: 16px !important;
      .col-12.no-padding {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
      .col-12.no-padding.padding-left, .p-col-6.no-padding.padding-left {
        padding-left: 8px !important;
      }
      .col-12.no-padding.padding-right, .p-col-6.no-padding.padding-right {
        padding-right: 8px !important;
      }
    }
    .p-field.no-padding.p-lg-6.p-col-12,
    .p-field.col-lg-6.col-12.no-padding {
      padding-right: 16px !important;
    }
    .p-field.no-padding.p-lg-6.d-lg-block.d-none,
    .p-field.col-lg-6.col-12.no-padding.d-lg-block.d-none {
      padding-left: 16px !important;
    }
  }
  
  h5 {
    font-weight: bold;
  }
  .vs__dropdown-toggle{
    background: white;
  }
  .p-inputgroup-addon{
  height: 45px;
}
</style>