<template>
  <div class="p-fluid function-view">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-12 manager-title-custom pl-4">{{ title }}</div>
          <!-- <div class="p-col-8 txt-right">
            <Toolbar class="fixed-bottom-toolbar bg-transparent border-0">
              <template slot="right">
                <Button
                  label="Quay lại"
                  @click="backToList()"
                  class="p-button-warning callback-btn"
                />
                <Button
                  v-if="mode == 'edit'"
                  :label="'Lưu'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
                <Button
                  v-else
                  :label="'Thêm mới'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
              </template>
            </Toolbar>
          </div> -->
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
import ApiRepository from "@/core/ApiRepository";
// import ApiRepository from "@/core/ApiRepository";
import moment from "moment-timezone";
// import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Thêm Mới Khách Hàng",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      triggerSave: false,
      time_zone_local : moment.tz.guess(true),
      code: '',
      request_pay_old:false,
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editCustomer' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa khách hàng' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CUSTOMER);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-customer' });
    },
    
    async saveData(params) {
        this.$commonFuction.is_loading(true);
        var uid = this.$store.getters.user;
        let objects = {
          code: params.code,
          name: params.name,
          company_id: params.company_id,
          group_channel_id: params.group_channel_id,
          channel_id: params.channel_id,
          region_id: params.region_id,
          active: Boolean(params.active),
          note: params.note,
          note_doc: params.note_doc,
          request_pay: Boolean(params.request_pay),
          is_pickup: Boolean(params.is_pickup),
          commitment_time: params.commitment_time,
          // DeliveryDelete: params.DeliveryDelete,
          // ContactDelete: params.ContactDelete,
          // FreightDelete: params.FreightDelete,
          // DocumentDelete: params.DocumentDelete,
          updated_at: moment(new Date()),
          updated_by: uid
        }
        let deliveries = params.deliveries;
        let documents = params.documents;
        let contacts = params.contacts;
        let prefix_code_cus = params.prefix_code_cus;
        console.log(contacts);
        let freights = params.freights;
        
        if(this.mode == 'add'){
          objects['created_at'] = moment(new Date());
          objects['created_by'] = uid;
          await this.$apollo.mutate({
              mutation: DataServices.insertData('customer'),
              variables: {
                objects: objects
              }
          }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                var cus_code = this.$commonFuction.padNum(returning.ordinal, 6);
                var code = prefix_code_cus + cus_code;
                /*let variables_0 = {
                  'data_update': {code: code},
                  'where_key': {
                    'id': {'_eq': returning.id}
                  }
                }
                await this.$apollo.mutate({
                    mutation: DataServices.updateData('customer'),
                    variables: variables_0
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });*/


                for (let i=0; i < deliveries.length; i++) {
                    deliveries[i].customer_id = returning.id;
                    deliveries[i].code = code+deliveries[i].code;
                    this.$delete(deliveries[i], 'id');
                }
                let variables_1 = {
                    objects: deliveries
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('customer_delivery'),
                    variables: variables_1
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                for (let i=0; i < documents.length; i++) {
                    documents[i].customer_id = returning.id;
                }
                let variables_2 = {
                    objects: documents
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('customer_document'),
                    variables: variables_2
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                for (let i=0; i < contacts.length; i++) {
                    contacts[i].customer_id = returning.id;
                }
                let variables_3 = {
                    objects: contacts
                };
                console.log(variables_3);
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('contact'),
                    variables: variables_3
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                for (let i=0; i < freights.length; i++) {
                    freights[i].customer_id = returning.id;
                    this.$delete(freights[i], 'id');
                }
                let variables = {
                    objects: freights
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('freight_customer'),
                    variables: variables
                }).then(() => {
                    this.backToList();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }else{
         /* console.log("this.deliveriesthis.deliveries:",deliveries)
          var testss=1;
          if (testss==1){
            return false;
          }*/
          console.log("objectsobjects:",objects)
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('customer'),
            variables: variables
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = await res.data[Object.keys(res.data)].returning[0];
               // var delivery_ids = [];
                for (let i=0; i < deliveries.length; i++) {
                  /*if (deliveries[i]?.id) {
                    delivery_ids.push(deliveries[i]?.id)
                    this.$delete(deliveries, i);
                  } else {*/
                    deliveries[i].customer_id = returning.id;
                    deliveries[i].code = deliveries[i]?.check_code ? deliveries[i].code : params.code+deliveries[i].code;

                    this.$delete(deliveries[i], 'check_code');

                   // this.$delete(deliveries[i], 'id');

                  //}
                }
                var list_current_delivery_id = [];
                var list_insert_delivery = [];
                var list_update_delivery = [];
                var list_deliveries_convert = this.$commonFuction.convertJsonObject(deliveries);
                for (let i=0; i < list_deliveries_convert.length; i++) {
                  var item_data_d = list_deliveries_convert[i]
                  this.$delete(item_data_d, 'check_code');
                  item_data_d.customer_id = returning.id;
                  console.log("item_data_d:",item_data_d)
                  if (!this.$commonFuction.isEmpty(item_data_d.id)){
                    //this.$delete(item_data_d, 'id');
                    list_current_delivery_id.push(item_data_d.id)
                    list_update_delivery.push(item_data_d)
                  }else {
                    this.$delete(item_data_d, 'id');
                    list_insert_delivery.push(item_data_d)
                  }
                }


                console.log("list_update_delivery:",list_update_delivery)
                console.log("list_insert_delivery:",list_insert_delivery)
                await this.$apollo.mutate({
                    mutation: DataServices.updateData('customer_delivery'),
                    variables: {
                        'data_update': {"deleted": true},
                        'where_key': {
                            'customer_id': {'_eq': this.models.id},
                            'id': {'_nin': list_current_delivery_id}
                        }
                    }
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                let variables_3 = {
                    objects: list_insert_delivery
                };
                if (list_insert_delivery.length>0) {
                  await this.$apollo.mutate({
                    mutation: DataServices.insertData('customer_delivery'),
                    variables: variables_3
                  }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                      severity: 'error',
                      summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                      detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                      life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                  });
                }
              if (list_update_delivery.length>0) {
                for (var d=0;d<list_update_delivery.length;d++) {
                  await this.$apollo.mutate({
                    mutation: DataServices.updateData('customer_delivery'),
                    variables: {
                      'data_update': list_update_delivery[d],
                      'where_key': {
                        'id': {'_eq': list_update_delivery[d].id},
                        //'id': {'_nin': list_current_delivery_id}
                      }
                    }
                  }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                      severity: 'error',
                      summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                      detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                      life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                  });
                }
              }
                await this.$apollo.mutate({
                    mutation: DataServices.deleteData('contact'),
                    variables: {
                        'where_key': {
                            'customer_id': {'_eq': this.models.id}
                        }
                    }
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                for (let i=0; i < contacts.length; i++) {
                  contacts[i].customer_id = returning.id;
                }
                let variables_2 = {
                    objects: contacts
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('contact'),
                    variables: variables_2
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                await this.$apollo.mutate({
                    mutation: DataServices.deleteData('customer_document'),
                    variables: {
                        'where_key': {
                            'customer_id': {'_eq': this.models.id}
                        }
                    }
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                for (let i=0; i < documents.length; i++) {
                  documents[i].customer_id = returning.id;
                }
                let variables_1 = {
                    objects: documents
                };
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('customer_document'),
                    variables: variables_1
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                
                var freights_arr = [];
                var list_in_freights = []
                for (let i=0; i < freights.length; i++) {
                  //console.log("freights[i]?.idfreights[i]?.id:",freights[i]?.id)
                  if (freights[i]?.id) {
                    freights_arr.push(freights[i].id);
                    //freights_arr.push(freights[0].id);
                    //this.$delete(freights, i);
                    //continue;
                  }
                  freights[i].customer_id = returning.id;
                  //this.$delete(freights[i], 'id');
                  var object_data = freights[i]
                  list_in_freights.push({
                    customer_id:object_data.customer_id,
                    unit_price:object_data.unit_price,
                    freight_id:object_data.freight_id,
                  })
                 // console.log("list_in_freightslist_in_freights:",list_in_freights)
                }
                //console.log("freightsfreights:",freights)
                await this.$apollo.mutate({
                    mutation: DataServices.updateData('freight_customer'),
                    variables: {
                        'data_update': {"deleted": true},
                        'where_key': {
                            'customer_id': {'_eq': this.models.id},
                            //'id': {'_nin': freights_arr}
                        }
                    }
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
                let variables = {
                    objects: list_in_freights
                };
              if (Boolean(params.request_pay) != this.request_pay_old){
                console.log("checngrequest_pay")
                var params_update_trigger = {
                  request_payment:Boolean(params.request_pay),
                  customer_id:this.models.id
                }
                await this.trigger_update_request_payment(params_update_trigger)
                }
                await this.$apollo.mutate({
                    mutation: DataServices.insertData('freight_customer'),
                    variables: variables
                }).then(() => {
                    this.redirectEdit();
                }).catch(async error => {
                    console.log('error', error);
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                        detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                        life: 3000
                    });
                    this.$commonFuction.is_loading(false);
                });
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        });
      }
      this.$commonFuction.is_loading(false);
    },
    async trigger_update_request_payment(dataRequest) {
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/update_change_request_payment', dataRequest, {headers});
        return true
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    // async saveData(params) {
    //   // console.log("params",params)
    //   let objects = {
    //     'first_name' : params.first_name,
    //     'last_name' : params.last_name,
    //     'phone': params.phone,
    //     'email' : params.email,
    //     'full_birthday': params.full_birthday,
    //     'gender': params.gender,
    //     'date_booking': params.date_booking,
    //     'go_with': params.go_with,
    //     'uid': params.uid,
    //     'status': params.status,
    //     'patient_code': params.patient_code,
    //     'type_service_id': params.type_service_id,
    //     'branch_id': params.branch_id,
    //     'time_id': params.time_id,
    //     'prefix_phone': '+84'
    //   }
    //   let services = params.services;
    //   let questions = params.questions;
    //   this.triggerSave = false;
    //   if(this.mode == 'add'){
    //     console.log("ADD")
    //     await this.$apollo.mutate({
    //         mutation: DataServices.insertData('booking'),
    //         variables: {
    //           objects: objects
    //         }
    //     }).then(async (res) => {
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //             var returning = res.data[Object.keys(res.data)].returning[0];
    //             var servicesData = []
    //             for (let i=0; i < services.length; i++) {
    //                 servicesData.push({
    //                     'booking_id': returning.id,
    //                     'service_id': services[i].id,
    //                     'service_name': services[i].name
    //                 });
    //             }
    //             var questionsData = []
    //             for (let i=0; i < questions.length; i++) {
    //                 questionsData.push({
    //                     'booking_id': returning.id,
    //                     'question_id': questions[i].id,
    //                     'answer': questions[i].answer,
    //                     'question_title': questions[i].name
    //                 });
    //             }
    //             let variables = {
    //                 objects: servicesData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_line'),
    //                 variables: variables
    //             });
                
    //             let variables_question = {
    //                 objects: questionsData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_question'),
    //                 variables: variables_question
    //             }).then(() => {
    //                 this.redirectEdit();
    //             }).catch(async error => {
    //                 console.log('error', error);
    //                 this.$toast.add({
    //                     severity: 'error',
    //                     summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                     detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                     life: 3000
    //                 });
    //                 this.$commonFuction.is_loading(false);
    //             });
    //         }
    //     })
    //   }else{
    //     // var user_id = this.$route.params.id;
    //     let variables = {
    //       'data_update': objects,
    //       'where_key': {
    //         'id': {'_eq': this.models.id}
    //       }
    //     }
    //     await this.$apollo.mutate({
    //         mutation: DataServices.updateData('booking'),
    //         variables: variables
    //     }).then(async (res) => {
    //         if (res.data[Object.keys(res.data)].affected_rows) {
    //             var returning = res.data[Object.keys(res.data)].returning[0];
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.deleteData('booking_line'),
    //                 variables: {
    //                     'where_key': {
    //                         'booking_id': {'_eq': this.models.id}
    //                     }
    //                 }
    //             })
                
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.deleteData('booking_question'),
    //                 variables: {
    //                     'where_key': {
    //                         'booking_id': {'_eq': this.models.id}
    //                     }
    //                 }
    //             })
                
    //             var servicesData = []
    //             for (let i=0; i < services.length; i++) {
    //                 servicesData.push({
    //                     'booking_id': returning.id,
    //                     'service_id': services[i].id,
    //                     'service_name': services[i].name
    //                 });
    //             }
    //             var questionsData = []
    //             for (let i=0; i < questions.length; i++) {
    //                 questionsData.push({
    //                     'booking_id': returning.id,
    //                     'question_id': questions[i].id,
    //                     'answer': questions[i].answer,
    //                     'question_title': questions[i].name
    //                 });
    //             }
    //             let variables = {
    //                 objects: servicesData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_line'),
    //                 variables: variables
    //             });
                
    //             let variables_question = {
    //                 objects: questionsData
    //             };
    //             await this.$apollo.mutate({
    //                 mutation: DataServices.insertData('booking_question'),
    //                 variables: variables_question
    //             }).then(() => {
    //                 this.redirectEdit();
    //             }).catch(async error => {
    //                 console.log('error', error);
    //                 this.$toast.add({
    //                     severity: 'error',
    //                     summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
    //                     detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
    //                     life: 3000
    //                 });
    //                 this.$commonFuction.is_loading(false);
    //             });
    //         }
    //     })
    //   }
    //   this.$commonFuction.is_loading(false);
    // },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-customer'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('customer', 
          {"fields" : "id name code active note_doc note request_pay commitment_time group_channel{id name code channel{id code prefix_code_cus}} region{id name} company{id name code}  region{id name} company{id name code} customer_documents(where:{deleted: {_eq: false}}){document{id name} qty_delivery return_quantity} customer_deliveries(where:{deleted: {_eq: false}}){id code country{id name} state{id name} district{id name} ward{id name} address} freight_customers(where:{deleted: {_eq: false}}){id freight{id name} unit_price} contacts {id name phone email}"}
        ),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {'_eq': false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.request_pay_old = this.models.request_pay
        //console.log("this.request_pay_old:",this.request_pay_old)
        if(this.models.active==false){
          this.active_check = false
        }
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 24px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.function-view .p-toolbar-group-right {
  flex-basis: 100%;
  justify-content: flex-end;
}
.function-view .p-button-warning.p-button {
  margin: 0 10px;
}
</style>