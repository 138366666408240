<template>
    <Panel>
        <div class="row">
            <div class="col-12 p-0">
                <div class="p-formgrid">                                
                    <div class="p-card-field p-grid">
                        <div class="col-12 d-flex pb-3 align-items-center">
                            <h5 class="pr-3 m-0">ĐỊA CHỈ GIAO HÀNG</h5>
                            <Button class="p-button-success" style="width: 24px !important; height: 24px !important" icon="fa fa-plus" @click="addDelivery"/>
                        </div>
                        <div class="col-lg-6 col-12 py-3" v-for="(delivery, index) in deliveries" :key="index">
                            <Card>
                                <template #header>
                                    <div class="d-flex p-3 justify-content-between">
                                        <h5>ĐỊA CHỈ {{ index + 1 }}</h5>
                                        <Button class="p-button-danger" icon="fa fa-trash" @click="delDelivery(index)"/>
                                    </div>
                                </template>
                                <!-- <template #title>
                                    Advanced Card
                                </template> -->
                                <template #content>
                                    <div class="row pt-0 px-3" style=" margin-top: -2rem;">
                                        <div v-if="mode == 'edit'" class="col-12 p-0">
                                            <label class="p-col-fixed txt-left">Mã địa chỉ</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputText v-model="delivery.code" disabled="disabled"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-12 p-0">
                                            <label class="p-col-fixed txt-left">Quốc gia</label>
                                            <div class="p-col mr-lg-2">
                                                <v-select :clearable="false" title="Quốc gia" :id="'country-'+index" :filterable="false" placeholder="Chọn" v-model="delivery.selCountry" :options="listCountry" label="name" @search="fetchOptionsCountry" @search:blur="changeCountry(index)">
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_country-'+index">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-12 p-0" @mouseenter="load_list_state_click(delivery.selCountry)" >
                                            <label class="p-col-fixed txt-left"  >Tỉnh/ Thành phố</label>
                                            <div class="p-col ml-lg-2"  >
                                                <v-select  :clearable="false" title="Tỉnh/ Thành phố" :id="'state-'+index" :filterable="false" placeholder="Chọn" v-model="delivery.selState" :options="listStateTmp" label="name" @search="(search, loading) => { fetchOptionsState(search, loading, delivery.selCountry?.id, index) }" @search:blur="changeState(index)">
                                                    <span slot="no-options" >
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_state-'+index">
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-6 col-12 p-0" @mouseenter="load_list_district_click(delivery.selState)">
                                            <label class="p-col-fixed txt-left">Quận/ Huyện</label>
                                            <div class="p-col mr-lg-2">
                                                <v-select :clearable="false" title="Quận/ Huyện" :id="'district-'+index" :filterable="false" placeholder="Chọn" v-model="delivery.selDistrict" :options="listDistrictTmp" label="name" @search="(search, loading) => { fetchOptionsDistrict(search, loading, delivery.selState?.id, index) }" @search:blur="changeDistrict(index)">
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_district-'+index">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-12 p-0" @mouseenter="load_list_ward_click(delivery.selDistrict)">
                                            <label class="p-col-fixed txt-left">Phường/ Xã</label>
                                            <div class="p-col ml-lg-2">
                                                <v-select :clearable="false" title="Phường/ Xã" :id="'ward-'+index" :filterable="false" placeholder="Chọn" v-model="delivery.selWard" :options="listWardTmp" label="name" @search="(search, loading) => { fetchOptionsWard(search, loading, delivery.selDistrict?.id, index)}" >
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_ward-'+index">
                                            </div>
                                        </div>
                                        
                                        <div class="col-12 p-0">
                                            <label class="p-col-fixed txt-left">Số nhà, tên đường</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputText :id="'street-'+index" v-model="delivery.street" placeholder="Nhập" maxlength="255"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- <template #footer>
                                    <Button icon="pi pi-check" label="Save" />
                                    <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em" />
                                </template> -->
                            </Card>
                        </div>
                        <div class="col-12 text-center" v-if="deliveries.length === 0">
                            Chưa có địa chỉ giao hàng
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>
<script>
import DataServices from '@/core/DataServices'
export default {
  props: {
    mode: String,
    deliveries: Array,
    DeliveryDelete: Array,
    CustomerCode: String,
    countDelivery: Number
  },
  data() {
    return {
      key_state:0,
        tmp_deliveries: [],
        
        listCountry: [],
        listCountryAll: [],
        
        listStateTmp: [],
        listState: [],
        listStateAll: [],
        
        listDistrictTmp: [],
        listDistrict: [],
        listDistrictAll: [],
        
        listWardTmp: [],
        listWard: [],
        listWardAll: [],
        
        ordinalCode: (this.deliveries.length + 1) || 1,
        maxOrdinal: 0,

    }
  },
  async created() {

    await this.getListCountry();
    this.deliveries.map(m => {
        this.$set(m, 'listState', []);
        this.$set(m, 'listStateAll', []);
        this.$set(m, 'listDistrict', []);
        
        this.$set(m, 'listDistrictAll', []);
        this.$set(m, 'listWard', []);
        this.$set(m, 'listWardAll', []);
        if ((m?.ordinal || 0) > this.maxOrdinal) {
            this.maxOrdinal = m?.ordinal || 0;
        }
    });

    /*for (var j=0;j<this.deliveries.length;j++){
        var item_data = this.deliveries[j];
        var selCountry = item_data.selCountry
        var selState = item_data.selState
        var selDistrict = item_data.selDistrict
        if (!this.$commonFuction.isEmpty(selCountry.id)){
          this.deliveries[j].listState = await this.getListStateConvert(selCountry.id)
        }
        if (!this.$commonFuction.isEmpty(selState.id)){
          this.deliveries[j].listDistrict = await this.getListDistrictConvert(selState.id)
        }
      if (!this.$commonFuction.isEmpty(selDistrict.id)){
        this.deliveries[j].listWard = await this.getListWardConvert(selDistrict.id)
      }
    }*/
    console.log("this.deliveries:",this.deliveries)
    if (this.deliveries.length == 0){
        this.addDelivery();
    }
    this.ordinalCode = (this.deliveries.length + 1);

    // else {
    // }
    console.log(this.deliveries.length);
},
mounted(){
},
  methods: {
    async load_list_state_click(item_country) {
      console.log("55555")
      if (item_country){
        this.listStateTmp = await  this.getListStateConvert( item_country.id)
      }
    },
    async load_list_district_click(item_state) {
      if (item_state){
        this.listDistrictTmp = await  this.getListDistrictConvert( item_state.id)
      }
    },
    async load_list_ward_click(item_district) {
      if (item_district){
        this.listWardTmp = await  this.getListWardConvert( item_district.id)
      }
    },
    genrateDeliveryCode() {
        return this.CustomerCode + "_" + this.$commonFuction.padNum(this.ordinalCode, 3);
    },
    async getListCountry() {
        var where_key = {
            deleted : {_eq : false},
            active : {_eq : true}
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('country', {"fields" : `id name`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : "asc" }]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name: e.name});
        }
        this.listCountry = list_origin;
        this.listCountryAll = list_origin;
    },
    
    async fetchOptionsCountry(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('country',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name :e.name});
        }
        this.listCountry = list_origin;
      }else{
        // cosnole.log("dasdasd")
        this.listCountry = this.listCountryAll
      }
    },
    async getListStateConvert(country_id) {
      console.log(country_id);
      if (this.$commonFuction.isEmpty(country_id)) {
        return [];
      }
      var where_key = {
        deleted : {_eq : false},
        active : {_eq : true},
        country_id : {_eq : country_id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('state', {"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : "asc" }]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_origin = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_origin.push({id : e.id, name: e.name});
      }
      return list_origin

    },
    async getListState(index, country_id) {
        console.log(country_id);
        if (this.$commonFuction.isEmpty(country_id)) {
            this.deliveries[index].listState = [];
            this.deliveries[index].listStateAll = [];
            return;
        }
        var list_origin =  await this.getListState(country_id);
        console.log("list_origin:",list_origin)
        console.log("this.deliveries:",this.deliveries)
        this.deliveries[index].listState = list_origin;
        this.deliveries[index].listStateAll = list_origin;

    },
    
    async fetchOptionsState(search, loading, country_id, index){
        console.log("55555555555");
        console.log(loading);
        if (this.$commonFuction.isEmpty(country_id)) {
            this.deliveries[index].listState = [];
            this.deliveries[index].listStateAll = [];
            return;
        }
        if(search !== '' && search !== null){
            var where_key = {
            // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            deleted : {_eq : false},
            active : {_eq : true},
            plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            country_id: {_eq: country_id}
            };
            let {data} = await this.$apollo.query({
            query: DataServices.getList('state',{"fields" : `id name`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'asc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_origin = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_origin.push({id : e.id, name :e.name});
            }
            this.listState = list_origin;
            this.listStateTmp = list_origin;
        }else{
            // cosnole.log("dasdasd")
            this.deliveries[index].listState = this.deliveries[index].listStateAll
          this.listStateTmp = this.deliveries[index].listStateAll
        }
    },
    async getListDistrictConvert( state_id) {
      if (this.$commonFuction.isEmpty(state_id)) {
        return [];
      }
      var where_key = {
        deleted : {_eq : false},
        active : {_eq : true},
        state_id : {_eq : state_id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('district', {"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : "asc" }]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_origin = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_origin.push({id : e.id, name: e.name});
      }
      return list_origin
    },
    async getListDistrict(index, state_id) {
        if (this.$commonFuction.isEmpty(state_id)) {
            this.deliveries[index].listDistrict = [];
            this.deliveries[index].listDistrictAll = [];
            return;
        }
        var list_origin = await this.getListDistrictConvert(state_id);
        this.deliveries[index].listDistrict = list_origin;
        this.deliveries[index].listDistrictAll = list_origin;
    },
    
    async fetchOptionsDistrict(search, loading, state_id, index){
        console.log(loading);
        if (this.$commonFuction.isEmpty(state_id)) {
            this.deliveries[index].listDistrict = [];
            this.deliveries[index].listDistrictAll = [];
            return;
        }
        if(search !== '' && search !== null){
            var where_key = {
            // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            deleted : {_eq : false},
            active : {_eq : true},
            plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            state_id: {_eq: state_id}
            };
            let {data} = await this.$apollo.query({
            query: DataServices.getList('district',{"fields" : `id name`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'asc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_origin = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_origin.push({id : e.id, name :e.name});
            }
            this.deliveries[index].listDistrict = list_origin;
            this.listDistrictTmp = list_origin;
        }else{
            // cosnole.log("dasdasd")
            this.deliveries[index].listDistrict = this.deliveries[index].listDistrictAll
            this.listDistrictTmp = this.deliveries[index].listDistrictAll
        }
    },
    async getListWardConvert( district_id) {
      if (this.$commonFuction.isEmpty(district_id)) {
        return [];
      }
      var where_key = {
        deleted : {_eq : false},
        active : {_eq : true},
        district_id : {_eq : district_id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('ward', {"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : "asc" }]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_origin = [];
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_origin.push({id : e.id, name: e.name});
      }
      return list_origin
    },
    async getListWard(index, district_id) {
        if (this.$commonFuction.isEmpty(district_id)) {
            this.deliveries[index].listWard = [];
            this.deliveries[index].listWardAll = [];
            return;
        }
        var list_origin = await this.getListWardConvert(district_id);
        this.deliveries[index].listWard = list_origin;
        this.deliveries[index].listWardAll = list_origin;
    },
    
    async fetchOptionsWard(search, loading, district_id, index){
        console.log(loading);
        if (this.$commonFuction.isEmpty(district_id)) {
            this.deliveries[index].listWard = [];
            this.deliveries[index].listWardAll = [];
            return;
        }
        if(search !== '' && search !== null){
            var where_key = {
            // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            deleted : {_eq : false},
            active : {_eq : true},
            plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            district_id: {_eq: district_id}
            };
            let {data} = await this.$apollo.query({
            query: DataServices.getList('ward',{"fields" : `id name`}),
            variables: {
                where_key: where_key,
                orderBy:[{created_at : 'asc'}]
            },
            fetchPolicy: "network-only"
            })
            var list = data[Object.keys(data)];
            var list_origin = [];
            // console.log("list===",list);
            for (let i = 0; i < list.length; i++) {
            const e = list[i];
            list_origin.push({id : e.id, name :e.name});
            }
            this.deliveries[index].listWard = list_origin;
            this.listWardTmp = list_origin;
        }else{
            // cosnole.log("dasdasd")
            this.deliveries[index].listWard = this.deliveries[index].listWardAll
            this.listWardTmp = this.deliveries[index].listWardAll
        }
    },
    
    addDelivery() {
        this.deliveries.push({
            id: null, 
            // 'tmp_id-'+this.$commonFuction.generateUUID(),
            code: this.genrateDeliveryCode(),
            selCountry: null,
            selState: null,
            selDistrict: null,
            selWard: null,
            street: '',
            ordinal: this.ordinalCode,
            listState: [],
            listStateAll: [],
            listDistrict: [],
            listDistrictAll: [],
            listWard: [],
            listWardAll: []
        });
        this.ordinalCode +=1;
    },
    
    delDelivery(index) {
        var check = this.deliveries[index]?.id?.split("tmp_id") || [];
        if (check.length === 1) {
            this.DeliveryDelete.push(this.deliveries[index].id);
        }
        this.deliveries.splice(index, 1);
    },
    async changeCountry(index) {
        await this.getListState(index, this.deliveries[index].selCountry?.id)
        this.$set(this.deliveries[index], 'selState', null);
        this.$set(this.deliveries[index], 'selDistrict', null);
        this.$set(this.deliveries[index], 'selWard', null);
    },
    async changeState(index) {
      console.log("asdasd",index)
        await this.getListDistrict(index, this.deliveries[index].selState?.id)
        this.$set(this.deliveries[index], 'selDistrict', null);
        this.$set(this.deliveries[index], 'selWard', null);
    },
    async changeDistrict(index) {
        await this.getListWard(index, this.deliveries[index].selDistrict?.id)
        this.$set(this.deliveries[index], 'selWard', null);
    },
  }
}
</script>

<style lang="scss">
.freight-table input[disabled=disabled], .freight-table .vs--disabled .vs__dropdown-toggle {
    background: transparent;
    border: unset;
    opacity: 1;
}
button{
    .fa.fa-plus{
        width: 14px !important;
        height: 14px !important;
        font-size: 14px !important;
    }
}
</style>

